import React from 'react';
const Test = () => {
    return (
        <section className="fix-nav-spacer">
            <div className="container">
                <h1 className="t-center">Test page</h1>
            </div>
        </section>
    )
}

export default Test
