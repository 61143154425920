import React from 'react';
const NotFound = () => {
    return (
        <>
            <section className="fix-nav-spacer">
                <div className="container">
                    <h1 className="t-center">
                        404
                    </h1>
                </div>
            </section>
        </>
    )
}

export default NotFound


